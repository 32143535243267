import { useRecoilValue } from "recoil";
import { currentPersonIDAtom } from "../../services/state/state";
import TodosDashboard from "../todos/todos-dashboard";
import Meetings from "./meetings";
import Agenda from "../agenda/agenda";
import styles from "./meetings-dashboard.module.scss";
import { useLoggingActions } from "../../services/api/logging-service";

const MeetingsDashboard = () => {
  const currentPersonID = useRecoilValue(currentPersonIDAtom);
  const loggingActions = useLoggingActions();

  loggingActions.logToClient("MeetingsDashboard mounted");
  return (
    <div className={styles.meetingsDashboard}>
      <div
        className={styles.meetingsDashboardMain}
        data-testid="dashboard-main"
      >
        <Meetings personId={currentPersonID} />
      </div>
      <div
        className={styles.meetingsDashboardSidebar}
        data-testid="dashboard-sidebar"
      >
        <div className={styles.agenda} data-testid="dashboard-sidebar-agenda">
          <Agenda personId={currentPersonID} />
        </div>
        <div className={styles.todos} data-testid="dashboard-sidebar-todos">
          <TodosDashboard />
        </div>
      </div>
    </div>
  );
};

export default MeetingsDashboard;
