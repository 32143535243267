import styles from "./note-type-selector.module.scss";

const NoteTypeSelector = ({
  selectedValue,
  noteTypes,
  onNoteTypeSelect,
  editable,
}) => {
  return (
    <select
      className={styles.noteTypeSelector}
      value={selectedValue}
      onChange={(e) => {
        onNoteTypeSelect(e.target.value);
      }}
      disabled={!editable}
    >
      {noteTypes.map((nt, idx) => {
        return (
          <option key={idx + 1} value={nt.name}>
            {nt.name}
          </option>
        );
      })}
    </select>
  );
};

export default NoteTypeSelector;
