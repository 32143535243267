import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useUserActions } from "../../services/api/user-service";
import { Note as NoteModel } from "../../models/note";
import NoteTypeSelector from "./note-type-selector";
import AccessTypeSelector from "../access-type-selector/access-type-selector";
import styles from "./note-detail.module.scss";

const NoteDetail = ({
  note,
  noteTypeOptions,
  noteAccessTypes,
  onSave,
  onDelete,
}) => {
  const submitButton = useRef(null);

  const SAVE_INTERVAL_IN_MILLIS = 10000;
  const userActions = useUserActions();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      title: note?.title || "",
      text: note?.text || "",
      noteTypes: note?.noteTypes || [{ name: "" }],
      accessType: note?.accessType || null,
      isAgendaItem: note?.isAgendaItem || false,
      isArchived: note?.isArchived || false,
    },
  });

  const currentUserOwnsNote = note?.isOwnedBy(
    userActions.getLoggedInUser().userId
  );
  const currentUserCanEditNote = note?.isEditableBy(
    userActions.getLoggedInUser().userId
  );

  // Watch form values
  const formValues = watch();

  // Update form when note changes
  useEffect(() => {
    if (note) {
      setValue("title", note.title);
      setValue("text", note.text);
      setValue("noteTypes", note.noteTypes || [{ name: "" }]);
      setValue("accessType", note.accessType);
      setValue("isAgendaItem", note.isAgendaItem);
      setValue("isArchived", note.isArchived);
    }
  }, [note, setValue]);

  useEffect(() => {
    const timer = setInterval(() => {
      if (isDirty) {
        submitButton.current?.click();
      }
    }, SAVE_INTERVAL_IN_MILLIS);
    return () => {
      clearInterval(timer);
    };
  }, [isDirty]);

  const onSubmit = (data) => {
    if (!note) return;

    const newNote = new NoteModel({
      id: note.id,
      ownerId: note.ownerId,
      title: data.title,
      text: data.text,
      noteTypes: data.noteTypes,
      accessType: data.accessType,
      isAgendaItem: data.isAgendaItem,
      isArchived: data.isArchived,
    });

    onSave(newNote);
  };

  const deleteNote = (noteId) => {
    console.log(`deleting note with id ${noteId}`);
    onDelete(noteId);
  };

  if (!note) {
    return <div className={styles.noNotesMessage}>No note selected</div>;
  }

  return (
    <form
      className={styles.noteDetail}
      onSubmit={handleSubmit((data) => {
        onSubmit(data);
      })}
      data-testid="note-detail"
    >
      <ul>
        <li>
          <div className={styles.noteTypes}>
            <NoteTypeSelector
              selectedValue={formValues.noteTypes[0]?.name}
              noteTypes={noteTypeOptions}
              onNoteTypeSelect={(newValue) => {
                setValue("noteTypes", [{ name: newValue }]);
              }}
              editable={currentUserCanEditNote}
            />
          </div>
        </li>
        <div className={styles.accessTypes}>
          <AccessTypeSelector
            accessTypes={noteAccessTypes}
            selectedAccessType={formValues.accessType}
            onAccessTypeSelect={(newAccessType) =>
              setValue("accessType", newAccessType)
            }
            editable={currentUserCanEditNote}
          />
        </div>
        <li className={styles.checkboxContainers}>
          <div className={styles.checkboxContainer}>
            <input
              type="checkbox"
              checked={formValues.isAgendaItem}
              onChange={(e) => setValue("isAgendaItem", e.target.checked)}
              disabled={!currentUserCanEditNote}
            />
            <label>Agenda Item</label>
          </div>
          <div className={styles.checkboxContainer}>
            <input
              type="checkbox"
              checked={formValues.isArchived}
              onChange={(e) => setValue("isArchived", e.target.checked)}
              disabled={!currentUserCanEditNote}
            />
            <label>Archived</label>
          </div>
        </li>
      </ul>
      <label>
        Title
        <input
          type="text"
          {...register("title")}
          disabled={!currentUserCanEditNote}
          className={styles.titleInput}
          placeholder="Note Title"
          data-testid="note-title"
        />
      </label>
      <div className={styles.myEditor}>
        <textarea
          {...register("text")}
          disabled={!currentUserCanEditNote}
          className={styles.textInput}
          rows={10}
        />
      </div>
      <footer>
        <input
          className={styles.saveButton}
          id="editor-save-button"
          type="submit"
          value="Save"
          disabled={!currentUserCanEditNote}
          ref={submitButton}
          data-testid="submit-button"
        />
        {currentUserOwnsNote && (
          <button
            type="button"
            onClick={() => {
              deleteNote(note.id);
            }}
            className={styles.deleteLink}
          >
            Delete
          </button>
        )}
      </footer>
    </form>
  );
};

export default NoteDetail;
