export class Person {
  constructor({
    id,
    userId,
    ownerId,
    firstName,
    lastName,
    email,
    teamId,
    status,
    createdDate,
    modifiedDate,
  }) {
    this.id = id;
    this.userId = userId;
    this.ownerId = ownerId;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.teamId = teamId;
    this.status = status;
    this.createdDate = createdDate;
    this.modifiedDate = modifiedDate;
  }
}
