import { API_URL } from "../../constants";
import axiosInstance from "./api";
import { Person } from "../../models/person";

function usePersonActions() {
  const createPerson = async ({ firstName, lastName, email, teamId }) => {
    try {
      return axiosInstance.post(`${API_URL}/persons`, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        team_id: teamId,
      });
    } catch (error) {
      console.error("Error creating person", error);
      throw error;
    }
  };

  const retrievePersons = async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/persons`);
      const persons = response.data.map((person) => {
        return new Person({
          id: person.id,
          userId: person.user_id,
          ownerId: person.owner_id,
          firstName: person.first_name,
          lastName: person.last_name,
          email: person.email,
          teamId: person.team_id,
          status: person.status,
          createdDate: person.created_date,
          modifiedDate: person.modified_date,
        });
      });
      return persons;
    } catch (error) {
      console.error("Error retrieving persons", error);
      throw error;
    }
  };

  const retrieveTeamPersons = async (teamId) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL}/teams/${teamId}/persons`
      );
      const persons = response.data.map((person) => {
        console.log(`person: ${JSON.stringify(person)}`);

        return new Person({
          id: person.id,
          userId: person.user_id,
          ownerId: person.owner_id,
          firstName: person.first_name,
          lastName: person.last_name,
          email: person.email,
          teamId: person.team_id,
          status: person.status,
          createdDate: person.created_date,
          modifiedDate: person.modified_date,
        });
      });
      return persons;
    } catch (error) {
      console.error(`Error retrieving persons for team with id ${teamId}`);
      throw error;
    }
  };

  const retrievePerson = async (id) => {
    try {
      const response = await axiosInstance.get(`${API_URL}/persons/${id}`);
      return new Person({
        id: response.data.id,
        userId: response.data.user_id,
        ownerId: response.data.owner_id,
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        email: response.data.email,
        teamId: response.data.team_id,
        status: response.data.status,
        createdDate: response.data.created_date,
        modifiedDate: response.data.modified_date,
      });
    } catch (error) {
      console.error(`Error retrieving person with id ${id}`, error);
      throw error;
    }
  };

  const updatePerson = async ({ id, firstName, lastName, email, teamId }) => {
    try {
      return axiosInstance.put(`${API_URL}/persons/${id}`, {
        first_name: firstName,
        last_name: lastName,
        email: email,
        team_id: teamId,
      });
    } catch (error) {
      console.error(`Error updating person with id ${id}`, error);
      throw error;
    }
  };

  const deletePerson = async (id) => {
    try {
      axiosInstance.delete(`${API_URL}/persons/${id}`);
    } catch (error) {
      console.error(`Error deleting person with id ${id}`, error);
      throw error;
    }
  };

  const invitePerson = async (email, personId, teamId) => {
    try {
      console.log(`person-service: inviting person with id ${personId}`);
      const response = await axiosInstance.post(
        `${API_URL}/persons/${personId}/invite`,
        {
          email: email,
          team_id: teamId,
        }
      );
      return response.data;
    } catch (error) {
      console.error(`Error inviting person with id ${personId}`, error);
      throw error;
    }
  };

  return {
    createPerson,
    retrievePersons,
    retrieveTeamPersons,
    retrievePerson,
    updatePerson,
    deletePerson,
    invitePerson,
  };
}

export { usePersonActions };
