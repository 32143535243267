import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useUserActions } from "../../services/api/user-service";
import { Meeting as MeetingModel } from "../../models/meeting";
import AccessTypeSelector from "../access-type-selector/access-type-selector";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./meeting-note.module.scss";

const MeetingNote = ({ meeting, meetingAccessTypes, onSave, onDelete }) => {
  const submitButton = useRef(null);
  const SAVE_INTERVAL_IN_MILLIS = 10000;
  const userActions = useUserActions();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      title: meeting?.title || "",
      text: meeting?.text || "",
      date: meeting?.date || new Date(),
      accessType: meeting?.accessType || "",
      personId: meeting?.personId || "",
    },
    mode: "onChange",
  });

  const currentUserOwnsMeeting = meeting?.isOwnedBy(
    userActions.getLoggedInUser().userId
  );
  const currentUserCanEditMeeting = meeting?.isEditableBy(
    userActions.getLoggedInUser().userId
  );

  // Watch form values
  const formValues = watch();

  // Update form when meeting changes
  useEffect(() => {
    if (meeting) {
      setValue("title", meeting.title);
      setValue("text", meeting.text);
      setValue("date", meeting.date);
      setValue("accessType", meeting.accessType);
      setValue("personId", meeting.personId);
    }
  }, [meeting, setValue]);

  // Auto-save functionality
  useEffect(() => {
    const timer = setInterval(() => {
      if (isDirty) {
        console.log("Auto-save triggered, isDirty:", isDirty);
        submitButton.current?.click();
      }
    }, SAVE_INTERVAL_IN_MILLIS);
    return () => clearInterval(timer);
  }, [isDirty]);

  const onSubmit = (data) => {
    if (!meeting) return;

    const newMeeting = new MeetingModel({
      id: meeting.id,
      ownerId: meeting.ownerId,
      personId: data.personId,
      title: data.title,
      text: data.text,
      date: data.date,
      accessType: data.accessType,
    });
    onSave(newMeeting);
  };

  const deleteMeeting = (meetingId) => {
    console.log(`deleting meeting with id ${meetingId}`);
    onDelete(meetingId);
  };

  if (!meeting) {
    return <div className={styles.noMeetingMessage}>No meeting selected</div>;
  }

  return (
    <form
      className={styles.meetingNote}
      onSubmit={handleSubmit((data) => {
        console.log("Form handleSubmit wrapper called");
        onSubmit(data);
      })}
      data-testid="meeting-note"
    >
      <header>
        <div className={styles.meetingDate}>
          <DatePicker
            className={styles.datePicker}
            id="meeting-date"
            selected={formValues.date}
            onChange={(date) => setValue("date", date)}
            disabled={!currentUserCanEditMeeting}
          />
        </div>
        <div className={styles.accessTypes}>
          <AccessTypeSelector
            accessTypes={meetingAccessTypes}
            selectedAccessType={formValues.accessType}
            onAccessTypeSelect={(newAccessType) =>
              setValue("accessType", newAccessType)
            }
            editable={currentUserCanEditMeeting}
          />
        </div>
      </header>
      <div className={styles.myEditor}>
        <input
          type="text"
          {...register("title")}
          disabled={!currentUserCanEditMeeting}
          className={styles.titleInput}
          placeholder="Meeting Title"
        />
        <textarea
          {...register("text")}
          disabled={!currentUserCanEditMeeting}
          className={styles.textInput}
          rows={10}
        />
      </div>
      <footer>
        <input
          className={styles.saveButton}
          id="editor-save-button"
          type="submit"
          value="Save"
          disabled={!currentUserCanEditMeeting}
          ref={submitButton}
          onClick={() => console.log("Save button clicked")}
        />
        {currentUserOwnsMeeting && (
          <button
            type="button"
            onClick={() => {
              deleteMeeting(meeting.id);
            }}
            className={styles.deleteLink}
          >
            Delete
          </button>
        )}
      </footer>
    </form>
  );
};

export default MeetingNote;
