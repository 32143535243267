import { useState, useMemo, useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
import { currentPersonIDAtom } from "../../services/state/state";
import NoteDetail from "./note-detail";
import Notes from "./notes";
import NotesHeader from "./notes-header";
import { Note } from "../../models/note";
import { useNotes } from "../../hooks/use-notes";
import { useAccessTypes } from "../../hooks/use-access-types";
import SearchContext from "../../context/search-context";
import styles from "./notes-dashboard.module.scss";

const NotesDashboard = () => {
  const [selectedNote, setSelectedNote] = useState(null);
  const [sortField, setSortField] = useState("createdAt");
  const [sortAscending, setSortAscending] = useState(false);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const currentPersonID = useRecoilValue(currentPersonIDAtom);

  const {
    isNoteTypeOptionsLoading,
    noteTypeOptionsError,
    noteTypeOptions,
    isNotesLoading,
    notesError,
    notes,
    createNote,
    updateNote,
    deleteNote,
  } = useNotes({
    personId: currentPersonID,
    filter: {},
    sort: { sortField: sortAscending ? "asc" : "desc" },
  });

  const { isAccessTypesLoading, accessTypesError, accessTypes } =
    useAccessTypes();

  useEffect(() => {
    if (selectedNote === null && notes && notes.length > 0) {
      console.log("setting selected note to first note");
      setSelectedNote(sortedNotes[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notes]);

  const sortedNotes = useMemo(() => {
    if (notes && notes.length > 0) {
      return [...notes].sort(
        (a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt)
      );
    }
    return [];
  }, [notes]);

  useEffect(() => {
    setFilteredNotes(sortedNotes);
  }, [sortedNotes]);

  const onNoteCreate = () => {
    const newNoteText = "";
    const newNoteRichText = `{"root":{"children":[{"children":[{"detail":0,"format":0,"mode":"normal","style":"","text":"${newNoteText}","type":"text","version":1}],"direction":"ltr","format":"","indent":0,"type":"paragraph","version":1}],"direction":"ltr","format":"","indent":0,"type":"root","version":1}}`;
    const newNoteTypes = [{ name: noteTypeOptions[0].name }];
    const privateAccessType = accessTypes.find((at) => at.value === "private");
    const newNote = new Note({
      title: "",
      text: newNoteText,
      richText: newNoteRichText,
      noteTypes: newNoteTypes,
      isAgendaItem: false,
      isArchived: false,
      accessType: privateAccessType,
      personId: currentPersonID,
    });

    createNote.mutateAsync(newNote).then((note) => {
      console.log(`created note with id ${note.id}`);
      setSelectedNote(null);
    });
  };

  const onNoteSelect = (note) => {
    console.log(`selected note with id: ${note.id}, text: ${note.text}`);
    setSelectedNote(note);
  };

  const onNoteSearch = (searchText) => {
    console.log(`searching for notes with text ${searchText}`);
    const results = sortedNotes.filter(
      (note) =>
        note.text.toLowerCase().includes(searchText.toLowerCase()) ||
        note.title.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredNotes(results);
  };

  const onNoteSave = ({
    id,
    title,
    text,
    richText,
    noteTypes,
    isAgendaItem,
    isArchived,
    accessType,
  }) => {
    console.log(
      `saving note with id ${id}, title ${title}, text ${text}, types ${noteTypes
        .map((nt) => nt.name)
        .join(", ")}, accessType: ${accessType} isAgendaItem ${isAgendaItem}`
    );

    const note = new Note({
      id: id,
      title: title,
      text: text,
      richText: richText,
      noteTypes: noteTypes,
      isAgendaItem: isAgendaItem,
      isArchived: isArchived,
      accessType: accessType,
      personId: currentPersonID,
    });

    updateNote.mutate(note);
  };

  const onNoteDelete = async (noteId) => {
    console.log(`deleting note with id ${noteId}`);

    const noteIndex = notes.findIndex((note) => note.id === noteId);
    if (noteIndex > -1) {
      if (noteIndex > 0) {
        setSelectedNote(notes[noteIndex - 1]);
      } else {
        setSelectedNote(null);
      }
    }

    deleteNote.mutateAsync(noteId).then(() => {
      console.log(`deleted note with id ${noteId}`);
    });
  };

  if (isNoteTypeOptionsLoading || isNotesLoading || isAccessTypesLoading) {
    return <div data-testid="loading">Loading...</div>;
  }

  if (notesError) {
    return <div data-testid="notes-error">Error: {notesError.message}</div>;
  }

  if (noteTypeOptionsError) {
    return (
      <div data-testid="note-type-options-error">
        Error: {noteTypeOptionsError.message}
      </div>
    );
  }

  if (accessTypesError) {
    return (
      <div data-testid="access-types-error">
        Error: {accessTypesError.message}
      </div>
    );
  }

  return (
    <div className={styles.notesDashboard} data-testid="notes-dashboard">
      <div className={styles.notesDashboardMain}>
        <SearchContext.Provider value={onNoteSearch}>
          <NotesHeader
            onSort={(ascending) => {
              console.log(`sorting. ascending = ${ascending}`);
              if (ascending) {
                setSortAscending(true);
              } else {
                setSortAscending(false);
              }
            }}
          />
        </SearchContext.Provider>

        <Notes
          notes={filteredNotes}
          selectedNote={selectedNote}
          onCreate={onNoteCreate}
          onSelect={onNoteSelect}
          currentPersonID={currentPersonID}
        />
      </div>
      <div className={styles.notesDashboardSide}>
        {selectedNote && (
          <NoteDetail
            note={selectedNote}
            noteTypeOptions={noteTypeOptions}
            noteAccessTypes={accessTypes}
            onSave={onNoteSave}
            onDelete={onNoteDelete}
          />
        )}
      </div>
    </div>
  );
};

export default NotesDashboard;
